#root{
    /* --ion-toolbar-background: var(--ion-color-primary-shade) */
}
.footer-text-style{
    font-size: small;
}
.all-width{width:100%;}

ion-button {
    text-transform: none;
   }

.show-click{
    cursor: pointer;
    background: #add8e69a;
}

.mini-image{
    max-width: 20px;
    max-height: 20px;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
   white-space: normal;
}
.alert-tappable.alert-radio {
   height: auto;
   contain: content;
}#ForCheckbox
.alert-checkbox-label.sc-ion-alert-md,
.alert-checkbox-label.sc-ion-alert-ios {
   white-space: normal;
}
.alert-tappable.alert-checkbox {
   height: auto;
   contain: content;
}
ion-popover ion-select-popover ion-radio-group ion-item ion-label{
    white-space: pre-line !important;
}