.filtersModal .modal-wrapper {
    --width:100%;
    --height:100%;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
}

.filtroModal .modal-wrapper {
    --width:40%;
    --height:40%;
        top: 0;
        left: 0;
        display: block;
        width: 40%;
        height: 40%;
}

.filtroModalShopStatus .modal-wrapper {
    --width:50%;
    --height:50%;
        top: 0;
        left: 0;
        display: block;
        width: 50%;
        height: 50%;
}

.filtroModalPropPics .modal-wrapper {
    --width:80%;
    --height:80%;
        top: 0;
        left: 0;
        display: block;
        width: 80%;
        height: 80%;
        max-width: 100%;
        max-height: 100%;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
   white-space: normal;
}
.alert-tappable.alert-radio {
   height: auto;
   contain: content;
}#ForCheckbox
.alert-checkbox-label.sc-ion-alert-md,
.alert-checkbox-label.sc-ion-alert-ios {
   white-space: normal;
}
.alert-tappable.alert-checkbox {
   height: auto;
   contain: content;
}
ion-popover ion-select-popover ion-radio-group ion-item ion-label{
    white-space: pre-line !important;
}