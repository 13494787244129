.myShopsPage .logo{max-width: 100px;margin:0 auto;}
.myShopsPage .title{font-size: 3rem;}
.myShopsPage .subtitle{font-size: 1rem;max-width: 90%;margin:0 auto;}
.myshopProductView{display:flex;flex-wrap: wrap;justify-content: center;align-items: center;grid-gap:50px;}


.headerPremium{min-height:50vh;display:grid;align-items:center;justify-content: center;box-shadow:0px 10px 10px rgb(85 116 151 / 50%);text-align: center;}
.headerPremium .socialMediaList{background:transparent;display: flex;flex-wrap:wrap;align-items: center;justify-content: center;}
.premiumContainer .headerBanner{position: relative;z-index:-1;}

.classicHeader{min-height:25vh;display:grid;align-items:center;justify-content: center;box-shadow:0px 10px 10px rgb(85 116 151 / 50%);text-align: center;} 

.freeContainer{height: 10vh;display:grid;align-items:center;justify-content: center;box-shadow:0px 10px 10px rgb(85 116 151 / 50%);text-align: center;}
@media(max-width:768px){
    .myShopsPage .title{font-size: 2rem;}
    .myShopsPage .subtitle{font-size: .9rem;}
}

@media(max-width:550px){
    .myShopsPage .title{font-size: 1.5rem;}
    .myShopsPage .subtitle{font-size: .8rem;}
}


