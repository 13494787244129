/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #0e52a0;
  --ion-color-primary-rgb: 14,82,160;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #0c488d;
  --ion-color-primary-tint: #2663aa;

  --ion-color-secondary: #90bff5;
  --ion-color-secondary-rgb: 144,191,245;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #7fa8d8;
  --ion-color-secondary-tint: #9bc5f6;

  --ion-color-tertiary: #E9098D;
  --ion-color-tertiary-rgb: 233,9,141;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #cd087c;
  --ion-color-tertiary-tint: #eb2298;

  --ion-color-success: #17a753;
  --ion-color-success-rgb: 23,167,83;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #149349;
  --ion-color-success-tint: #2eb064;

  --ion-color-warning: #ffc70e;
  --ion-color-warning-rgb: 255,199,14;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0af0c;
  --ion-color-warning-tint: #ffcd26;

  --ion-color-danger: #e92027;
  --ion-color-danger-rgb: 233,32,39;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #cd1c22;
  --ion-color-danger-tint: #eb363d;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,36,40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,245,248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}
