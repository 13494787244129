.pageOrderContainer{position: relative;text-align: center;}
.pageOrderContainer img{max-width:200px;max-height: 150px;margin: 0 auto;}
.pageOrderContainer .pageNumber{position:absolute;top:0;left:0;display:grid;justify-content:center;  align-items:center; width:100%;height:100%;text-align:center;background-color: rgba(var(--ion-color-secondary-rgb),.5);}
.pageOrderContainer .pageNumber p{color:var(--ion-color-tertiary);font-weight: bold;font-size:3rem;}
.columna{height:100%;display:grid;}
.container {
    margin: 10px;
    height: 300px;
    text-align: center;
}
.resize_fit_center {
    max-width:100%;
    max-height:100%;
    vertical-align: middle;
}