.input-fields{
    border:1px solid grey;border-radius:5px;
}

.signInForm .inputLabel ion-label {
	--color: var(--ion-color-medium);
}

.signInForm .inputLabelError ion-label {
	--color: var(--ion-color-danger);
}