.forgot-password-button {
	cursor: pointer;
	background-color: white;
	color: var(--ion-color-secondary);
	font-size: 14px;
	letter-spacing: 1px;
	-webkit-text-decoration-line: underline;
	text-decoration-line: underline; 
	margin: 0px;
	padding: 0px;
	margin-bottom: 8px;
}