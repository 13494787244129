.thumbnail{width:100%;max-width:50px;}
.checkoutBox{position: fixed;bottom: calc(100% - 450px);left:0;z-index: 0;width: 100%;}

@media(max-width:768px){
    .totalBox{position: fixed;bottom: 0;left: 0;z-index: 10;width: 100%;border-top: 1px solid var(--ion-color-tertiary); background: #fff;}
    .productosList{margin-bottom:30px;}

    .formsList{margin-bottom:250px;}
    .checkoutBox{bottom:0;border-top: 1px solid var(--ion-color-tertiary); background: #fff;}

}