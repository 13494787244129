.empty{min-height: 50vh;}
.catalogGrid{min-height: 50vh;display:grid;grid-template-columns: repeat(4,1fr);}
.modalProductos{text-align: center;}
.pagina{max-width:500px;margin:0 auto;}
.arrowContainer{z-index:10;width: 100%;top:-10px;
    display: grid;
    justify-content: space-between;
    grid-template-columns: 50px 50px;
    position: absolute;}

    @media(max-width:768px){
        .arrowContainer{top:50%;}
        .slidePremium .titulo span{display:none;}
        .catalogGrid{width: 100%;grid-template-columns: 1fr;}
    }

ion-card img { max-height: 240px; max-width: 345px;  }
/* .collection3 { max-height: 120px; max-width: 170px;} */

.container {
    margin: 10px;
    width: 115px;
    height: 115px;
    line-height: 115px;
    text-align: center;
    display: inline-block;
}

.containerSlide {
    margin: 10px;
    width: 250px;
    height: 400px;
    text-align: center;
    display: inline-block;
}

.containerResponsive {max-width: 300px; max-height: auto;margin: 10px;clear: both; display: block; overflow: auto;}

.resize_fit_center_responsive {
    max-width:100%;
    max-height:100%;
    display: block;
}

.resize_fit_center {
    max-width:100%;
    max-height:100%;
    vertical-align: middle;
}