.content {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 25px;
}
@media (max-width: 1000px) {
	.content {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 25px;
	}
}

@media (max-width: 768px) {
	.content {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
}
