.productContainer{box-shadow:1px 0px 5px rgba(200,200,200,.5);}
.product-view{width:280px;height:280px;background-color:white;position:relative;overflow:hidden;}
.product-view-wrapper{position:relative;}
.product-view .image img{width:100%;height:280px;}
.product-image{width:100%;height:280px; transform: translate(0%, -10%);}
.product-view .image ion-icon{width: 90%; height: 80%; display: block; top: 50%; left: 50%; transform: translate(-50%, -50%); position: absolute;}
.product-view .info .short{font-size:15px;color:darkgray;height:2.2em;}

.productContainer .info{transform:translate(0,50px);transition:all 300ms ease-in-out;opacity:0;padding:10px;width:100%;}
.productContainer:hover .info{transform:translate(0,0px);opacity:1;}
.price{font-weight:bold;font-size:1.3rem;color:var(--ion-color-tertiary);margin-top:5px;}
.name{font-size:16px;color:var(--ion-color-primary);}
.modal-wrapper{overflow: hidden;}
.imageModal{margin:60px ​0 0 0;}
.modalText{color:var(--ion-color-primary);}
@media(max-width:768px){
    .productContainer .info{transform:translate(0,0);opacity:1;}
}