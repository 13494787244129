.sign{font-size:2rem;}
.ionList{padding:0;}
.container{background-color: #ccc;}
.mainProfile{display:grid;grid-template-columns: 250px 1fr;grid-gap:1rem;}
.area{padding:1rem;box-shadow:0px 0px 10px rgba(200,200,200,.5);}
.lineaTertiary{width: 100%;height: 1px;border-bottom: 2px solid var(--ion-color-tertiary);    margin: 20px 0;}


.gridOrders{width:100%;overflow: 0;}
.gridOrders ion-row{display:grid;grid-template-columns: 50px 1fr 1fr 1fr 2fr;}
.gridOrders ion-col{margin:0;padding:0;}
.gridDeliveries{display:grid;grid-template-columns: repeat(3,1fr);}
@media(max-width:768px){
    .sign{font-size:1rem;}
    .mainProfile{grid-template-columns: 50px 1fr;}
    .listItem ion-label{display:none !important;}
    .listItem ion-icon{margin:0;padding: 0;transform:scale(2);}

    .gridDeliveries{grid-template-columns: repeat(2,1fr);}
}


@media(max-width:550px){
    .area{padding: 10px;}
    .sign{font-size:.8rem;}
    .gridOrders ion-row{grid-template-columns: 30px 45% 85px;}
    .gridOrders ion-row ion-button{font-size:.6rem;}
    .gridDeliveries{grid-template-columns: 1fr;}
}

@media(max-width:500px){
    .mainProfile{grid-template-columns: 50px calc(90% - 15px);grid-gap:0;}
    .gridOrders{width:100%;overflow-x:scroll ;}
}
