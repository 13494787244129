span.category{background-color:var(--ion-color-light); color:var(--ion-color-secondary);border-radius:100px;padding:5px;margin-right:10px;}


.carouselContainer{display:flex;}
.carouselContainer img{width:100%;}
.carouselContainer .single-slider{--progress-bar-background-active:var(--ion-color-light);}
.carouselContainer .single-slider img{width:100%;height:auto;transition:1s transform cubic-bezier(0.075, 0.82, 0.165, 1);}
.carouselContainer .single-slider img:hover{transform:scale(1.3);}
.carouselContainer .thumbs-slider{height:50vh;}
.carouselContainer .thumbs-slider img{height:100px;border:1px solid transparent;transition:1s border cubic-bezier(0.075, 0.82, 0.165, 1);}
.carouselContainer .thumbs-slider img:hover{cursor:pointer;border:1px solid var(--ion-color-tertiary);}

.priceProduct{font-size:2rem;margin:0;padding:0;}

.variationSelector{display:flex;flex-wrap:nowrap;flex-direction: row;justify-content: space-between;}
.variationImageSelector{width:50px;height:50px;object-fit: cover;margin:2.5px;border:2px solid transparent;transition:all .3s ease-in-out;}
.variationImageSelector.selected {border:2px solid var(--ion-color-secondary);}
.variationImageSelector:hover{cursor:pointer;border:2px solid #E9098D ;}


.divisor{background-color: var(--ion-color-tertiary);height:2px;border:0;}