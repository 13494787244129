.field-style{
    border:1px solid grey;
    border-radius:7px;
}

.error-field-style{
    border:1px solid var(--ion-color-danger);
    border-radius:7px;
}

.fields-style ion-input{
    --placeholder-color: var(--ion-color-dark);
    --placeholder-opacity: .4;
}

.error-labels{
    vertical-align: middle;
  }
  .alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
   white-space: normal!important;
}
.alert-tappable.alert-radio {
   height: auto;
   contain: content;
}#ForCheckbox
.alert-checkbox-label.sc-ion-alert-md,
.alert-checkbox-label.sc-ion-alert-ios {
   white-space: normal 
}
.alert-tappable.alert-checkbox {
   height: auto;
   contain: content;
}
.validate {
   --background: #f5beb2;
   
}
/* ion-card {
   border: 1px, solid red;
} */

.selectCard {height: 200px;}