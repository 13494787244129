.sliderContainer{box-shadow: 10px 10px 10px rgba(200,200,200,.5);}
.slide-style{
	--bullet-background:var(--ion-color-tertiary);
	--bullet-background-active:var(--ion-color-primary);
}


.renglon-start{margin-top:100px;}
.renglon-end{margin-bottom:100px;}
.title{font-size:2rem;}
@media(max-width:460px){
	.renglon-start{margin-top:50px;}
	.renglon-end{margin-bottom:50px;}
	.title{font-size: 1rem;}
}


.show-border {
	border: 1px solid grey;
	border-radius: 2px;
}

.show-border-red {
	border: 2px solid red;
	border-radius: 2px;
}

.custom-logo {
	max-width: 235px;
	/* background-color: linear-gradient(45deg, rgb(255, 255, 255) 0%, var(--ion-color-primary) 100%); */
}

.box-1-style {
	max-width: 263px;
}

.box-2-style {
	max-width: 322px;
}

.banner-1-style {
	max-width: 1500px;
}

.notifications-badge {
	position: absolute;
	right: -13px;
	top: 0px;
}

.my-custom-class {
	/* background: #222222;
    opacity: .1; */
}

.page-content {
}

.footer-css {
	background-color: var(--ion-color-dark);
}

.footer-css-2 {
	background-color: var(--ion-color-dark-tint);
}

.footer-css-2 {
	--color: var(--ion-color-dark-tint);
}

.san-alex {
	display: flex;
	justify-content: flex-end;
}

.alex-degradado-1 {
	background: linear-gradient(
		45deg,
		rgb(255, 255, 255) 0%,
		var(--ion-color-warning) 100%
	);
	text-indent: -9999px;
	width: 380px;
	height: 360px;
}

.alex-degradado-2 {
	background: linear-gradient(
		45deg,
		rgb(255, 255, 255) 0%,
		var(--ion-color-tertiary) 100%
	);
	text-indent: -9999px;
	width: 100px;
	height: 200px;
}

.alex-degradado-3 {
	background: linear-gradient(
		45deg,
		rgb(255, 255, 255) 0%,
		var(--ion-color-success) 100%
	);
	text-indent: -9999px;

	width: 790px;
	height: 450px;
}

.alex-degradado-4 {
	background: linear-gradient(
		45deg,
		rgb(255, 255, 255) 0%,
		var(--ion-color-primary) 100%
	);
	text-indent: -9999px;
	width: 790px;
	height: 450px;
}

.slide-style {
	width: 100%;
	max-height: 450px;
}
.slideItem{width:100%;}
.slideItem img{width:100%;}

.header-toolbar-style {
	--background: var(--ion-color-dark);
	/* background: linear-gradient(45deg, rgb(255, 255, 255) 0%, var(--ion-color-primary) 100%); */
}

.lomasnuevo {
	background-color: white;
    margin:20px 0;
}
.lomasnuevo h2{
	text-align:center;
    padding:10px;
    padding-top:20px;
    text-transform: uppercase;
    font-size:20px;
    transform:scaleX(.8);
}
.lomasnuevo .content {
	display: grid;
    width:calc(100% - 50px);
    margin:0 auto;
    padding-bottom:30px;
	grid-template-columns: repeat(5, 1fr);
	grid-template-rows: repeat(2, 1fr);
	grid-gap: 20px;
}

.slidePremium{display:grid;}
.slidePremium:hover{cursor: pointer;}
.slidePremium img{width:100%;}
.slidePremium p{width:100%;text-align: center;}

.catalogSlide{padding:10px;height:75px;color:var(--ion-color-light);margin-bottom: 50px;width:200px;border-radius: 10px;display: grid;justify-content: center;align-items: center;}
.catalogSlide:hover{cursor: pointer;}
.catalogSlide.listado{background-color: var(--ion-color-tertiary);}
.catalogSlide.catalogo{background-color: var(--ion-color-primary);}

.catalogImg{width:25%;height:100%;min-height:150px;cursor:pointer}
.catalogImgCard{width:100%;height:100%;cursor:pointer}

@media screen and (max-width:500px) {
	.catalogImg{width:100%;}
	.catalogCard{width:100%;}
}

#promote {margin:60px}

