.image-preview{
	width:150px;
	height:150px;
	margin:auto;
	max-width:100%;
	border:1px solid var(--ion-color-secondary);
	background-color:rgba(255,255,255,.3);
	color:var(--ion-primary-contrast);
	position:relative;	
}
.image-preview > div{
	position:absolute;
	top:50%;
	transform:translateY(-50%);
}
.image-preview img{
	object-fit: cover;
	width:100%;
	height:100%;
}