.info .subtitle{text-transform: capitalize;}

.info .contactBox{min-height: 50vh;display:grid;align-items: center;justify-content: center;}
.info .contactBox .title{font-size: 2rem;}
.info .contactBox .subtitle{font-size: 1.5rem;}
.info .contactBox a{text-decoration: none;border-bottom: 5px solid var(--ion-color-primary);}
.info .cardLogo{padding:20px;}

.info table{margin: 20px 0;border:1px solid var(--ion-color-primary);padding:20px;}
.info table td,.info table th{width:50%;padding:10px;border-bottom: 1px solid var(--ion-color-primary);}
.info .card{padding:20px;}
@media(max-width:768px){
    .info .contactBox{min-height: 30vh;}

}